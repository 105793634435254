          import React, {useEffect, useState, useRef} from 'react'
import {useLocation} from 'react-router-dom';
import './FullShop.css';
import Navbar from '../../components/Navbar/Navbar';
import ContactUs from '../../components/ContactUs/ContactUs';
import { useNavigate } from 'react-router-dom';
import { Spinner, Center, Box } from '@chakra-ui/react';

const FullShop = () => {
    const contactUsRef = useRef(null);
    const location= useLocation();
    const{cat_id}= location.state || {};
    const [products, setProducts]= useState([]);
    const [categoryDetails, setCategoryDetails]= useState([]);
    const [loading, setLoading]= useState(true);
    const navigate= useNavigate();
    

    const handleProductClick= (p_id)=>{
      window.scrollTo({top:0});
      navigate('/products',{
        state: {productId: p_id}
      }
      )
    }

    useEffect(()=>{
        const fetchProducts= async ()=>{
            try{
                const response= await fetch(`https://leatherandstyle.netlify.app/.netlify/functions/getProductsByCategory?cat_id=${cat_id}`);
                if(response.status===200){
                  setLoading(false);
                  const data= await response.json();
                  const reversedProducts = data.product.reverse(); 
                  setProducts(reversedProducts);
                }
                
                // console.log('setProducts:',data.product);
            }catch(e){
                console.error('error in fetching products:', e);
            }
        };

        const fetchCategoryDetails= async ()=>{
            try{
                const response= await fetch(`https://leatherandstyle.netlify.app/.netlify/functions/getCategoryDetails?cat_id=${cat_id}`);
                if(response.status===200){
                  setLoading(false);
                  const data= await response.json();
                  setCategoryDetails(data.CategoryData[0]);
                }
                
            }catch(e){
                console.error('error in fetching category data:', e);
            }
        }
        if(cat_id){
            fetchCategoryDetails();
            fetchProducts();
        }
    }, [cat_id]);
  return (
    <div className='shop-container'>
    <Navbar contactUsRef={contactUsRef}/>
    {
      loading ? (
        <Center height="100vh" background="rgba(255, 255, 255, 0.9)">
           <Box textAlign="center">
               <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
               <Box mt={4} fontSize="lg" color="gray.600">
                   Please wait loading the products...
               </Box>
           </Box>
      </Center>
      ) : (
        <>
        <div className="category-header">
          <h1 className='category-headline'>{categoryDetails.cat_name}</h1>
          <img src={categoryDetails.cat_img} alt='#' className="category-image" /> 
          <p className="category-intro">{categoryDetails.cat_tagline}</p>
        </div>

      <div className="products-grid">
        {products.map((product) => (
          <div key={product.p_id} className="product-card" onClick={()=> handleProductClick(product.p_id)}>
            <img src={product.p_image[0]} alt={product.p_name} className="product-img" />
            <div className="product-details">
              <h2 className="product-name">{product.p_name}</h2>
              <p className="product-price">₹{product.p_price-((product.p_disc/100)*product.p_price)} <span className='cut-price1'>Rs{product.p_price}/- </span> <span className="diff-disc1"> {product.p_disc}% OFF</span></p>
            </div>
          </div>
        ))}
      </div>
    </>
      )
    }
    
    <div ref={contactUsRef}>
        <ContactUs />
      </div>
  </div>
  )
}

export default FullShop;
