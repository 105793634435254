import React , { useRef, useEffect, useState } from 'react';
import './Profile.css';
import Navbar from '../../components/Navbar/Navbar';
import ContactUs from '../../components/ContactUs/ContactUs';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserProvider';

const Profile = () => {
  const location= useLocation();
  const {user, loading}= useUser();
    const contactUsRef = useRef(null);
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [primaryAddress, setPrimaryAddress] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isAddressSaved, setIsAddressSaved] = useState(false);
    const [orderData, setOrderData]= useState('');
  



    const fetchUserData = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch('https://leatherandstyle.netlify.app/.netlify/functions/getUser', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
    
          const data = await response.json();
          if (response.ok) {
            setUserData(data);
            setPrimaryAddress(data.primary_address || ''); // Set primary address if available
          } else {
            console.error('Error fetching user data:', data.error);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      const fetchUserOrders = async () =>{
        try{

          const response = await fetch(`http://localhost:8888/.netlify/functions/fetchUserOrders?Userid={}`, {
            method: 'GET',
            headers :{
              'Content-Type': 'application/json',
            },
          });

          if(response.ok){
            const data = await response.json();
            setOrderData(data);
          }else{
             console.log('failed to fetch order details');
          }
          

        }catch(e){
          console.log('error in fetching order details', e);
        }
      }
    
      // Call fetchUserData when the component mounts
      // useEffect(() => {
      //   fetchUserData();
      // }, []);

      const handleSaveAddress = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch('https://leatherandstyle.netlify.app/.netlify/functions/saveAddress', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ primary_address: primaryAddress }),
          });
    
          const data = await response.json();
          if (response.ok) {
            alert('Primary address saved successfully!');
            setIsEditing(false); // Exit edit mode after saving
          } else {
            console.error('Error saving address:', data.error);
          }
        } catch (error) {
          console.error('Error saving address:', error);
        }
      };
    
      const handleEditAddress = () => {
        setIsEditing(true);
      };

    const handleLogout = () => {
        localStorage.removeItem('token'); 
        alert('You have been logged out successfully.');
        window.scrollTo({ top: 0});
        navigate('/login'); 
      };



  return (
    <div className='Profile-container'>
        <Navbar contactUsRef={contactUsRef}/>
        <div className='Profile-main-container'>
            <div className='white-blur-screen'>
                <div className='left-part'>
                    <div className='nav-gav'>
                        <header className='profile-heading'>
                        Welcome! {location.state?.first_name || user?.first_name} { location.state?.first_name || user?.last_name},
                        </header>
                         <div className='purchased-orders'>
                            <div className='po-header'>
                                Your Orders
                            </div>
                            <div className='heading-underline'></div>
                            <div className='order-display'>
                                {/* Orders will be displayed here */}
                            </div>
                        </div> 
                    </div>
                </div>
                <div className='right-part'>
                    <div className='button-area'>
                        <button className='profile-logout' onClick={handleLogout}>Logout</button>
                        <div className='Adress-area'>
                            <div className='add-header'>
                               Delivery Address
                            </div>
                            <div className='add-heading-underline'></div>
                            {/* <div className='address-username'>
                            {userData.first_name} {userData.last_name}
                            </div> */}
                              {isEditing ? (
                                <div className='user-primary-address'>
                                    <textarea
                                        type='text'
                                        value={primaryAddress}
                                        onChange={(e) => setPrimaryAddress(e.target.value)}
                                        placeholder='Enter your primary address'
                                    />
                                    <button 
                                        className='address-save-button'
                                        onClick={() => {
                                            // Save the address (make API call or handle locally)
                                            handleSaveAddress();
                                            setIsEditing(false); // After saving, switch to non-edit mode
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            ) : (
                                <div className='user-primary-address'>
                                    <div>{primaryAddress || 'Please Enter Your Delivery Address'}</div>
                                    <button className='address-save-button' onClick={() => setIsEditing(true)}>
                                        {primaryAddress ? 'Edit' : 'Add Address'}
                                    </button>
                                </div>
                            )}

                        </div>
                    </div>
                    <div className='address'>
                    </div>
                </div>
            </div>
        </div>
        <div ref={contactUsRef}>
        <ContactUs/>
      </div>
    </div>
  )
}

export default Profile;
