// components/InvoiceDownload.js
import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { base64 } from "./base64"; // Import your base64 logo here
import {Button} from '@chakra-ui/react'

const InvoiceDownload = ({ invoiceData }) => {
  const handleDownloadInvoice = () => {
    const doc = new jsPDF();

    // Header
    doc.setFontSize(18);
    doc.text("Invoice", 105, 20, { align: "center" });
    doc.line(10, 35, 200, 35); // Line below the header

    // Company Details
    const imageHeight = 10; // Desired logo height
    const imageWidth = 10;  // Desired logo width
    doc.addImage(base64, "PNG", 10, 37, imageWidth, imageHeight);
    doc.setFontSize(14);
    doc.text("Leather and Style", 105, 45, { align: "center" });
    doc.setFontSize(10);
    doc.text("Address: Thane | Phone: +91 99 67 29 63 85 / +91 99 87 89 89 88 | Email: leatherandstyle71@gmail.com", 105, 52, { align: "center" });
    doc.text("GSTIN: 27AJQPH7200N1ZU | PAN: AJQPH7200N", 105, 58, { align: "center" });
    doc.line(10, 60, 200, 60); 

    // Buyer Details
    const buyerDetailsY = 70;
    doc.text("Buyer Details:", 10, buyerDetailsY);
    doc.text(`Name: ${invoiceData.shippingDetails.first_name} ${invoiceData.shippingDetails.last_name}`, 10, buyerDetailsY + 6);
    doc.text(`Phone: ${invoiceData.shippingDetails.phone}`, 10, buyerDetailsY + 12);
    doc.text(`Address: ${invoiceData.shippingDetails.address}`, 10, buyerDetailsY + 18);

    // Order Details
    
    doc.text("Order Details:", 130, buyerDetailsY);
    doc.text(`Transaction Id: ${invoiceData.mihpayid}`, 130, buyerDetailsY + 6);
    doc.text(`Order No: ${invoiceData.orderId}`, 130, buyerDetailsY + 12);
    doc.text(`Transaction Time: ${invoiceData.updatedAt}`, 130, buyerDetailsY + 18);

    // Order Summary Table
    const tableStartY = buyerDetailsY + 30;
    doc.setFontSize(14);
    doc.text("Order Summary", 105, tableStartY,{ align: "center" });
    const bodyData = invoiceData.products.map((product, index) => [
      index + 1,
      product.name,
      product.quantity,
      product.price || product.pricePerUnit,
      product.discount || product.discountPerUnit,
      product.total,
    ]);

    bodyData.push([
      { content: "Grand Total", colSpan: 5, styles: { halign: "right", fontStyle: "bold" } },
      invoiceData.products.reduce((sum, product) => sum + product.total, 0),
    ]);

    doc.autoTable({
      startY: tableStartY + 5,
      head: [["S.No.", "Item", "Qty", "Price", "Disc. %", "Total"]],
      body: bodyData,
      theme: "grid",
    });

    // Totals
    const summaryStartY = doc.lastAutoTable.finalY + 10;
    doc.setFontSize(10);
    doc.setFont('Helvetica');
    doc.text(`Total Discounted Price: ${invoiceData.totalDiscount}/-`, 140, summaryStartY);
    doc.text(`Shipping Charges: 40 /-`, 140, summaryStartY + 6);
    doc.text(`Total Payable Price After Discount: ${invoiceData.totalPayable}/-`, 140, summaryStartY + 12);
    doc.text(`Payment Method: Online`, 140, summaryStartY + 18);

    // Footer
    doc.line(10, summaryStartY + 20, 200, summaryStartY + 20);
    doc.text("Thank you, visit again!", 10, summaryStartY + 30);
    doc.text("This is a digitally generated invoice and does not require a signature.", 10, summaryStartY + 36);
    doc.text("Sold by: Mrunali Saurabh Pawar, Leather and Style", 10, summaryStartY + 42);

    // Save PDF
    doc.save(`Invoice_${invoiceData.orderId}.pdf`);
  };

  return (
    <Button colorScheme="blue" onClick={handleDownloadInvoice}>
      Download Invoice
    </Button>
  );
};

export default InvoiceDownload;
